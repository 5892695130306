const Footer = () => {

    const currentDate = new Date();

    const currentYear = currentDate.getFullYear();

    

    return (
        <footer className="text-gray-600 w-screen bg-yellow-300 px-2  mx-auto md:px-5">
            <div className="mt-8 items-center px-10 justify-between sm:flex">
                <div className="mt-4 sm:mt-0">
                    <strong>&copy; {currentYear} FomoOnSolana.</strong>
                </div>
                <div className="mt-6 sm:mt-0">
                    <ul className="flex items-center space-x-4">
                        <li className="w-10 h-10 border rounded-full flex items-center justify-center">
                            <a href="https://twitter.com/FomoLana/status/1759088824301338731?t=oaP0VQsyJPaToeu3c5vH_w&s=19">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
</svg>
                            </a>
                        </li>

                    </ul>
                </div>
            </div>
            <style jsx>{`
                .svg-icon path,
                .svg-icon polygon,
                .svg-icon rect {
                    fill: currentColor;
                }
            `}</style>
        </footer>
    )
}

export default Footer