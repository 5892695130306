export const homepage = {
    "Innovation": "We constantly push boundaries and explore new possibilities in the crypto space. ",
    "Transparency": "We believe in open communication and honesty, fostering trust within our community.",
    "Community": "Our community is the heart of Fomolana, and we prioritize their needs and feedback.",
    "Resilience": "We persevere in the face of challenges, learning and growing from every experience."

}

export const tokenomics = {
    "Total Supply": ["100%", "$FOMO boasts an impressive total supply of 100 Billion Tokens!"],
    "Presale": ["40%", "The thrilling Presale phase encompasses 40 Billion tokens, offering exclusive early access!"],
    "Liquidity": ["30%", "Dive into the Liquidity pool, securing 30 Billion tokens for a robust market foundation!"],
    "Airdrop": ["10%", "Embark on the Airdrop adventure with 10 Billion tokens distributed to our vibrant community!"],
    "Marketing": ["15%", "Fueling growth! Marketing commands 15 Billion tokens to expand $Fomo's presence across the crypto landscape!"],
    "Team": ["5%", "Meet the dedicated Team, holders of 5 Billion tokens, committed to $Fomo's success!"],
};
