import toast from "react-hot-toast";

export const copyToClipboard = async (text) => {
    try {
      const permissions = await navigator.permissions.query({ name: "clipboard-write" });
      if (permissions.state === "granted" || permissions.state === "prompt") {
        await navigator.clipboard.writeText(text);
        toast.success("Presale Link copied to clipboard!")
      } else {
        throw new Error("Can't access the clipboard. Check your browser permissions.");
      }
    } catch (error) {
      toast.error(console.error('Error copying to clipboard:', error));
    }
  }