import { phone } from "../assets";
import { Show } from "./Show";
import { presale } from "../constants/presale";

import { copyToClipboard } from "../constants/copyToClipboard";

const Header = () => {

  return (
    <div className="w-100 bg-bg-image1">
      <div className="w-100 bg-black bg-opacity-50 flex flex-col items-center justify-center px-4 pt-16">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
          <p className="inline-block px-3 py-px mb-4 text-lg font-bold tracking-wider text-gray-100 uppercase rounded-full bg-teal-accent-400">
            Welcome to
          </p>
        </div>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-100 sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="9ef1ff62-feb2-41fe-8163-772b4c79de7b"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#9ef1ff62-feb2-41fe-8163-772b4c79de7b)"
                width="52"
                height="24"
              />
            </svg>
            <span className="">$</span>
          </span>{' '}
          FOMOLANA
        </h2>
        <h3 className="text-xl text-gray-100 md:text-lg text-bold">
                  <b>Welcome to $FOMO, where missed opportunities transform into rocket fuel for your portfolio 🚀! <br />
                  </b>We are going Live✨!
        </h3>
        </div>
        <Show>
          <Show.When isTrue={presale.presale} >
              <div className="flex flex-col items-center w-1/2 mb-4 md:flex-row md:px-16">
        <input
            value="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=Gz6QWTMkU7KjU1tE1jVghG4gKKcg1M2ettkZCGYETM7&fixed=in"
            readOnly
          required=""
          type="text"
          className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
              />
              <a href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=Gz6QWTMkU7KjU1tE1jVghG4gKKcg1M2ettkZCGYETM7&fixed=in">
                <button
            onClick={()=>copyToClipboard("https://raydium.io/swap/?inputCurrency=sol&outputCurrency=Gz6QWTMkU7KjU1tE1jVghG4gKKcg1M2ettkZCGYETM7&fixed=in")}
          className="inline-flex bg-yellow-300 items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-gray-600 transition duration-200 rounded shadow-md md:w-auto focus:shadow-outline focus:outline-none"
        >
          Trade On Raydium
        </button>
              </a>
      </div>
          </Show.When>
        </Show>
        
      <p className="max-w-md mb-10 text-xl sm:text-sm md:text-center text-gray-100">
        At Fomolana, our mission is to empower investors by transforming missed opportunities into rocket fuel for their portfolios. We believe in the power of resilience and innovation to drive positive change in the crypto space.
      </p>
      <img
        src={phone}
        className="w-full h-auto mx-auto md:w-auto md:max-w-md lg:max-w-lg"
        alt=""
      />
      </div>
      </div>
  );
};

export default Header
