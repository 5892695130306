import { NavBar, Header, About, Community, Content, HowTo, Tokenomics, Footer } from "./components";
import { Toaster } from "react-hot-toast";
const App = ()=> {
  return (
    <div className="">
      <Toaster />
      <div >
        <NavBar />
      </div>
      <Header />
      <About />
      <Content header="Our Humble Begining" content="Fomolana has evolved into a vibrant and dynamic ecosystem, fueled by the enthusiasm of its community members. Through strategic partnerships and relentless dedication, Fomolana has overcome challenges and embraced opportunities, shaping its path towards success." />
      <HowTo />
      <Community />
      <Tokenomics />
      <div className="sticky bottom-0">
        <Footer />
      </div>
    </div>
  );
}

export default App;
